body {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #343a40;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link.active {
  font-weight: bold;
}

.navbar-light .navbar-brand {
  color: #ffffff;
}

.sidebar a {
  color: #ffffff;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
}

.sidebar a:hover {
  background-color: #34495e;
}

.sidebar .user-account {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #34495e;
}

.sidebar .user-account img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.sidebar .user-account strong {
  display: block;
  margin-top: 10px;
}

#protected-content {
  display: none;
}

.google_btn {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}

.abcRioButton {
  width: 100% !important;
}

.content {
  margin-left: 260px;
  padding: 20px;
}
